html {
    scroll-behavior: smooth;
}

body {
    overflow-x: hidden;
}

.MuiAppBar-colorPrimary {
    color: #ffffff;
    background-color: #2f2f2f;
}

.MuiIconButton-colorSecondary {
    color: #ffffff;
}

header .logo {
    width: 50%;
}

button:focus, button:active {
    outline: none !important;
    border:none !important;
}

header .MuiInputLabel-outlined,
header .MuiIconButton-root.Mui-disabled,
header .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input,
header .MuiSvgIcon-root {
    color: #fff !important;
}

.dialog-main {
    & .MuiDialog-paper {
        max-width: 500px;
    }
}

.weather-desc p {
    font-weight: normal;
}

.footer {
    background-color: #2f2f2f;
    color: #ffffff;
}

.footer .MuiTypography-body1 {
    font-size: 12px;
}


@media only screen and (max-width: 768px) {
    .MuiTypography-body1 {
        font-size: 0.51rem;
    }

    .MuiTableCell-root {
        padding: 12px;
        font-size: 0.62rem;
    }
  }